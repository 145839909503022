.login-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(230 250 255);
    width: 100vw;
    height: 100vh;
}

.login-form-container{
    padding: 30px;
    box-shadow: -3px 7px 19px -3px;
    border-radius: 20px;
    background: white;
}
.login-form-container-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-header-title{
    color: rgba(0, 0, 95, 0.618);
    padding: 10px;
}
.login-header-title-second-color{
    color: var(--color-azul);
}

.login-form-container-label {
    color: var(--color-azul);
}

.login__label {
    color: rgb(21, 21, 21);
    margin-top: 20px;
    letter-spacing: 1px;
}

.header-color {
    color: #f7da26;
}

.header-login {
    font-weight: 800;
    color: white;
    text-transform: uppercase;
}

form {
    width: 424px;
}

.btn-outline-warning {
    color: #f7da26;
    border-color: #f7da26;
}

.btn-outline-warning:hover {
    background-color: #f7da26;
    color: black;
}

.btn-outline-warning:active {
    background-color: #f7da26;
    color: black;
}

.login__button {
    margin-top: 4vh;
    width: 50%;
}
.login-form-img{
    width: 10vw;
}
@media (max-width: 700px) {
    .login-form-container {
        padding: 10px 0px 10px 0px;
    }
    .login-form-img {
        width: 40vw;
    }
}