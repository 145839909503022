.mei-contenedor-padre{
    margin: 15vh 0px 15vh 0px;
}

.contenedor-mei-cards{
    display: flex;
    flex-direction: row;
}

.card-contenedor-padre{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    max-width: 300px;
    min-height: 130px;
    padding: 10px;
    margin: 0px 0px 0px 10px;
    box-shadow: 0px 2px 6px
}
.card-contenedor-padre h1{
 font-size: 11px;
}
.card-contenedor-padre:hover{
    box-shadow: 1px 4px 6px;
    transform: scale(1.01);
    transition: all 0.5s ease-out;
}
.offline{
    background: #e94a4e;
}
.online {
    background: #97ebbd;
}
.card-header-mei {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}
.card-header-mei h1 {
    min-width: 50px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    color: #464646;
    padding: 5px;
    border-left: 2px solid rgba(0, 0, 0, .125);
    margin: 5px 0px 0px 10px;
}

.letter-online {
    font-weight: bold;
    font-size: 12px;
    color: #5a5148;
}
.input-picker{
    max-width: 100px;
}
.mei-contenedor-padre-datepicker{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 4px 0px 4px;
}
.mei-contenedor-padre-datepicker h1{
    padding: 3px 1px 0px 0px;
    font-size: 16px;
}

.contenedor-buscador-por-fechas{
    display: flex;
    flex-direction: row;
}
.letter-offline {
    font-weight: bold;
    font-size: 12px;
    color: #5a5148;

}
.card-item-body-midle{
    display: flex;
     flex-direction: row;
     justify-content: flex-end;
     align-items: center;
}

.card-item-body{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.card-item-body h1 {
    margin: 5px 0px 0px 2px;
    font-weight: 100;
    font-size: 9px;
}

.card-footer-download{
    cursor: pointer;
}

.pdf-contenedor{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-css{
    margin-top: 30px;
}

.loader-center-mei{
    margin: 50% 0px 0px 50%;

}

.resultado-mei{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card-body-status{
    margin: 10px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    width: 100%;
}
.card-body-status h1{
    text-transform: uppercase;
    margin-right: 5px;
    letter-spacing: 1px;
}

.icon-loading {
    font-size: 25px;
    font-weight: 600;
    margin: 0 auto;
    display: block;
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 1s;
    -webkit-animation: rotate 1s linear infinite;
    -moz-animation: rotate 1s linear infinite;
    -o-animation: rotate 1s linear infinite;
    animation: rotate 8s linear infinite;

}
@-webkit-keyframes rotate{from{-webkit-transform: rotate(0deg)}
        to{-webkit-transform: rotate(360deg)}
    }
    @-moz-keyframes rotate{from{-moz-transform: rotate(0deg)}
        to{-moz-transform: rotate(359deg)}
    }
    @-o-keyframes rotate{from{-o-transform: rotate(0deg)}
        to{-o-transform: rotate(359deg)}
    }
    @keyframes rotate{from{transform: rotate(0deg)}
        to{transform: rotate(359deg)}
    }