body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-azul:rgb(71 167 239);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-aq1gdx-MuiDrawer-docked .MuiDrawer-paper{
z-index: 0;
}
.modal-content{
  margin-top: 20px;
}

.css-o5srbh-MuiPaper-root-MuiAppBar-root{
background-color: #05a1c7 !important;
}

.select-search__select::-webkit-scrollbar {
  width: 8px !important;
  /* Tamaño del scroll en vertical */
  height: 8px !important;
  /* Tamaño del scroll en horizontal */
  display: none !important;
  /* Ocultar scroll */
}

.select-search__select {
  max-height: 110px !important;
  overflow: scroll !important;
}